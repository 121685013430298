import { motion } from 'framer-motion';
import './Skills.css';

const skills = [
  { name: 'React', level: 90 },
  { name: 'JavaScript', level: 85 },
  { name: 'HTML/CSS', level: 90 },
  { name: 'Python', level: 80 },
  { name: 'Flutter', level: 75 },
  { name: 'SQL', level: 85 },
  { name: 'Firebase', level: 80 },
  { name: 'MongoDB', level: 75 },
  { name: 'PHP', level: 70 },
  { name: 'Git/Github', level: 85 },
];

const SkillBar = ({ name, level }) => {
  return (
    <div className="skill-bar">
      <div className="skill-info">
        <span className="skill-name">{name}</span>
        <span className="skill-percentage">{level}%</span>
      </div>
      <motion.div 
        className="skill-progress-bg"
        initial={{ width: 0 }}
        animate={{ width: `${level}%` }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      >
        <div className="skill-progress" style={{ width: `${level}%` }} />
      </motion.div>
    </div>
  );
};

const Skills = () => {
  return (
    <div className="skills-container">
      <motion.h2 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="section-title"
      >
        Technical Skills
      </motion.h2>
      
      <div className="skills-grid">
        {skills.map((skill, index) => (
          <motion.div
            key={skill.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <SkillBar name={skill.name} level={skill.level} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Skills;