import { useEffect } from 'react';
import Hero from '../components/Hero';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Experience from '../components/Experience';
import Education from '../components/Education';
import Contact from '../components/Contact';
import ThreeScene from '../components/ThreeScene';

const Home = () => {
  useEffect(() => {
    // Scroll animation logic
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="home">
      <section id="hero" className="animate-on-scroll">
        <Hero />
      </section>
      
      <section id="skills" className="animate-on-scroll">
        <Skills />
      </section>
      
      <section id="three-scene" className="animate-on-scroll">
        <ThreeScene />
      </section>
      
      <section id="projects" className="animate-on-scroll">
        <Projects />
      </section>
      
      <section id="experience" className="animate-on-scroll">
        <Experience />
      </section>
      
      <section id="education" className="animate-on-scroll">
        <Education />
      </section>
      
      <section id="contact" className="animate-on-scroll">
        <Contact />
      </section>
    </div>
  );
};

export default Home;