import { motion } from 'framer-motion';
import './Education.css';

const education = [
  {
    degree: "Bachelor of Computer Science",
    major: "Software Development",
    school: "Namibian University of Science & Technology",
    period: "2022-2025",
    achievements: [
      "Major in Software Development",
      "Relevant coursework in algorithms, data structures, and software engineering"
    ]
  },
  {
    degree: "Secondary Education",
    school: "Negumbo SSS",
    period: "2019-2021",
    achievements: [
      "Namibia Senior Secondary Certificate Ordinary Level",
      "Outstanding academic performance"
    ]
  },
  {
    degree: "React Development Certificate",
    school: "Udemy",
    period: "4 Weeks",
    achievements: [
      "Completed comprehensive React development course",
      "Built multiple projects using React"
    ]
  }
];

const Education = () => {
  return (
    <div className="education-container">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="section-title"
      >
        Education
      </motion.h2>

      <div className="education-grid">
        {education.map((edu, index) => (
          <motion.div
            key={index}
            className="education-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <div className="education-period">{edu.period}</div>
            <h3 className="education-degree">{edu.degree}</h3>
            {edu.major && <h4 className="education-major">{edu.major}</h4>}
            <h4 className="education-school">{edu.school}</h4>
            <ul className="education-achievements">
              {edu.achievements.map((achievement, i) => (
                <li key={i}>{achievement}</li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Education;