import { motion } from 'framer-motion';
import './Hero.css';

const Hero = () => {
  const downloadCV = () => {
    // Create a link to your CV file
    const link = document.createElement('a');
    link.href = '/path-to-your-cv.pdf'; // Add your CV file to public folder
    link.download = 'Festus-Alpheus-CV.pdf';
    link.click();
  };

  return (
    <div className="hero-container">
      <motion.div 
        className="hero-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1>FESTUS A. ALPHEUS</h1>
        <h2>Software Developer</h2>
        <p>Final-year software engineering student passionate about creating innovative solutions</p>
        
        <div className="hero-buttons">
          <motion.button 
            onClick={downloadCV}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="download-cv"
          >
            Download CV
          </motion.button>
          
          <motion.a 
            href="#contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="contact-btn"
          >
            Contact Me
          </motion.a>
        </div>
      </motion.div>
    </div>
  );
};

export default Hero;