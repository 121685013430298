import { Canvas } from '@react-three/fiber';
import { OrbitControls, Text3D, Center } from '@react-three/drei';
import { motion } from 'framer-motion-3d';
import './ThreeScene.css';

const ThreeScene = () => {
  return (
    <div className="three-scene-container">
      <Canvas
        camera={{ position: [0, 0, 5], fov: 75 }}
        style={{ height: '50vh' }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        
        <Center>
          <motion.group
            animate={{
              rotateY: [0, Math.PI * 2],
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            <mesh>
              <boxGeometry args={[1, 1, 1]} />
              <meshStandardMaterial color="#4a90e2" />
            </mesh>
          </motion.group>
        </Center>

        <OrbitControls enableZoom={false} />
      </Canvas>
    </div>
  );
};

export default ThreeScene;