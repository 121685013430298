import { motion } from 'framer-motion';

import './Projects.css';

const projects = [
  {
    title: "Dieting Application",
    description: "A Flutter app for tracking diet plans, providing personalized recommendations, and monitoring calorie intake.",
    technologies: ["Flutter", "Firebase", "Provider"],
    image: "/src/assets/dieting-app.jpg",
    link: "https://github.com/Fessynam"
  },
  {
    title: "Smart Spend Budgeting",
    description: "A budgeting app for managing expenses, setting limits, and tracking spending.",
    technologies: ["React", "Node.js", "MongoDB"],
    image: "/src/assets/budgeting-app.jpg",
    link: "https://github.com/Fessynam"
  },
  {
    title: "Client Management System",
    description: "A system for managing client records, tracking interactions, and communication.",
    technologies: ["PHP", "MySQL", "Bootstrap"],
    image: "/src/assets/client-management.jpg",
    link: "https://github.com/Fessynam"
  },
  {
    title: "Student Management System",
    description: "A platform for handling student data, attendance, and academic records.",
    technologies: ["React", "Firebase", "Material-UI"],
    image: "/src/assets/student-management.jpg",
    link: "https://github.com/Fessynam"
  }
];

const Projects = () => {
  // Function to handle image loading errors
  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/400x300"; // Fallback image
  };

  return (
    <div className="projects-container">
      <motion.h2 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="section-title"
      >
        Projects
      </motion.h2>

      <div className="projects-grid">
        {projects.map((project, index) => (
          <motion.div
            key={project.title}
            className="project-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ y: -10 }}
          >
            <img 
              src={project.image} 
              alt={project.title} 
              className="project-image" 
              onError={handleImageError}
            />
            <div className="project-content">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <div className="project-technologies">
                {project.technologies.map((tech) => (
                  <span key={tech} className="tech-tag">
                    {tech}
                  </span>
                ))}
              </div>
              <motion.a
                href={project.link}
                className="view-project"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Project
              </motion.a>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Projects;