import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBriefcase } from 'react-icons/fa';
import './Experience.css';

const experiences = [
  {
    title: "Chief Executive Officer | Developer",
    company: "FessyNam Tech Solutions",
    date: "2022 - Present",
    description: [
      "Led the development of client projects, including websites for ABI Ref & Electrical, Glow Reach, and Cubango Fresh",
      "Managed project timelines and deliverables, ensuring high-quality outcomes for clients",
      "Conducted market research to identify trends and customer needs, enhancing service offerings"
    ]
  },
  {
    title: "Lead Web Developer",
    company: "Link",
    date: "2023 - Present",
    description: [
      "Developed and maintained company websites",
      "Collaborated with marketing teams",
      "Ensured brand consistency across platforms"
    ]
  },
  {
    title: "Web Developer",
    company: "ABI Ref & Electricals",
    date: "2021-2022",
    description: [
      "Developed and maintained the company website",
      "Implemented marketing strategies",
      "Monitored website performance"
    ]
  }
];

const Experience = () => {
  return (
    <div className="experience-container">
      <h2 className="section-title">Professional Experience</h2>
      
      <VerticalTimeline>
        {experiences.map((experience, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            date={experience.date}
            iconStyle={{ background: '#4a90e2', color: '#fff' }}
            icon={<FaBriefcase />}
            animate={true}
          >
            <h3 className="vertical-timeline-element-title">{experience.title}</h3>
            <h4 className="vertical-timeline-element-subtitle">{experience.company}</h4>
            <ul className="experience-list">
              {experience.description.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default Experience;